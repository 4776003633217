import { post } from "./apiManager";

export const sendNotification = (body, onSuccess, onFailed, onComplete) => {
  post(
    "Notification",
    body,
    "sendNotification",
    onSuccess,
    onFailed,
    onComplete
  );
};
