import { post, postAsyncV2 } from "./apiManager";

export const getPatientsList = (
  phoneNumber,
  onSuccess,
  onFailed,
  onComplete
) => {
  post(
    `Patient`,
    {
      Mobile: phoneNumber,
    },
    "getPatientList",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const getPatientsListV2 = async (phoneNumber) => {
  const response = await postAsyncV2("/Patient", {
    Mobile: phoneNumber,
  });
  const data = await response.json();
  const patientList = data.Data;
  return patientList;
};

export const newPatient = (
  title,
  firstName,
  lastName,
  address,
  mobileNo,
  nicNo,
  birthday,
  gender,
  onSuccess,
  onFailed,
  onComplete
) => {
  post(
    `Patient`,
    {
      Address: address,
      DateOfBirth: birthday,
      FirstName: firstName,
      Gender: gender,
      Id: 0,
      LastName: lastName,
      Mobile: mobileNo,
      NIC: nicNo,
      Title: title,
    },
    "newPatient",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const getPatientById = (
  id,
  onSuccess = () => {},
  onFailed = () => {}
) => {
  fetch(`${process.env.REACT_APP_MEDICA_URL}/Patient/GetPatient`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: id,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      onFailed();
    });
};
