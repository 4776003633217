import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { appointmentStatus } from "../../app/apiManager/channelingStatusService";
import {
  getDoctor,
  getDoctorSpecialization,
} from "../../app/apiManager/doctorServices";
import { getPatientById } from "../../app/apiManager/patientServices";
import ContentCard from "../../components/ContentCard/ContentCard";
import DoctorSummery from "../../components/DoctorSummery/DoctorSummery";
import FlexBox from "../../components/FlexBox/FlexBox";
import TitleAndContent from "../../components/TitleAndContent/TitleAndContent";
import { APPOINTMENT_STATUS, PAYMENT_METHODS } from "../../const";
import { clearAll } from "../../reducers/appointmentInfoSlice";
import {
  clearAppointmentStatus,
  setAppointment,
} from "../../reducers/appointmentStatusSlice";

const StepOne = ({
  setActiveStep,
  appointment,
  doctor,
  setDoctor,
  setAppointmentCompleted,
  setAppointmentCancelled,
}) => {
  const { phoneNo } = useSelector((state) => state.appointmentInfo);
  const { patientEmail } = useSelector((state) => state.appointmentInfo);
  const storedAppointment = useSelector(
    (state) => state.appointmentStatus.appointment
  );
  const [patient, setPatient] = useState({});

  const [queryParams, setQueryParams] = useSearchParams();
  const [doctorSpecialization, setDoctorSpecialization] = useState("");
  const [patientDetails, setPatientDetails] = useState({});
  const [requestAttempt, setRequestAttempt] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(-1);
  const [channelingStatus, setChannelingStatus] = useState({
    status: "Apointment Confirmed",
    color: "darkgreen",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const appointmentId = queryParams.get("appointmentId");
    dispatch(clearAll());
    let timeout = null;
    let timeoutOnStatus = null;

    //get appoinment status
    if (requestAttempt < 3) {
      timeoutOnStatus = appointmentStatus(appointmentId, (res) => {
        if (res) {
          setRequestAttempt(3);
        } else {
          //if res is null try again
          setRequestAttempt(requestAttempt + 1);
        }
        const appointment = res[0];

        getDoctor(
          appointment.DoctorId,
          (res) => {
            setDoctor(res);
          },
          (err) => {
            // console.log(err);
          }
        );

        getPatientById(
          appointment.PatientId,
          (res) => {
            if (res.Status === 1000) {
              if (res.Data[0]) {
                let fetchedPatient = res.Data[0];
                let name =
                  fetchedPatient.Title +
                  " " +
                  fetchedPatient.FirstName +
                  " " +
                  fetchedPatient.LastName;
                let email = fetchedPatient.Email;
                let phoneNo = fetchedPatient.Mobile;
                // console.log(name, email, phoneNo);

                setPatient({
                  name,
                  email,
                  phoneNo,
                });
              } else {
                setPatient({});
              }
            }
            // console.log(res);
          },
          (err) => {
            // console.log(err);
          }
        );
        getDoctorSpecialization(appointment.DoctorId, (res) => {
          setDoctorSpecialization(res[0].SpecializationName);
        });

        //check the appointment with the stored appointment

        dispatch(setAppointment({ appointment: appointment }));

        // if (appointment.ChanalingStatus !== "pending") {
        //   setActiveStep(4);
        //   return;
        // }
        switch (appointment.ChanalingStatus) {
          case APPOINTMENT_STATUS.CANCELLED_AS_NO_SHOW: {
            setChannelingStatus({
              status: "Appointment Cancelled as No Show",
              color: "red",
            });
            setAppointmentCompleted(true);
            setAppointmentCancelled(true);
            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);

            // break;
          }
          case APPOINTMENT_STATUS.COMPLETED: {
            setChannelingStatus({
              status: "Appointment Completed",
              color: "darkgreen",
            });
            setAppointmentCancelled(false);
            setAppointmentCompleted(true);

            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);

            // break;
          }
          case APPOINTMENT_STATUS.CANCELLED_WITH_RESCHEDULED: {
            setChannelingStatus({
              status: "Appointment Cancelled and Rescheduled",
              color: "red",
            });
            setAppointmentCompleted(true);
            setAppointmentCancelled(true);
            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);

            // break;
          }
          case APPOINTMENT_STATUS.CANCELLED_WITH_REFUND: {
            setChannelingStatus({
              status: "Appointment Cancelled and Refunded",
              color: "red",
            });

            return setTimeout(() => {
              setAppointmentCompleted(true);
              setAppointmentCancelled(true);
              setActiveStep(4);
            }, 2000);

            // break;
          }
          default: {
            setChannelingStatus({
              status: "Apointment Confirmed",
              color: "darkgreen",
            });
          }
        }

        setPaymentMethod(appointment.UserSaved);
      });
    }

    //navigate to waiting for doctor in 10 sedonds

    // timeout = setTimeout(() => {
    //   setActiveStep(2);
    // }, 10000);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutOnStatus);
    };
  }, [requestAttempt]);

  let paymentChipText = "Loading...";

  switch (paymentMethod) {
    case 1:
      paymentChipText = "Online Payment";
      break;

    case PAYMENT_METHODS.PROMO:
      paymentChipText = "Promo";
      break;

    case PAYMENT_METHODS.CASH_PAYMENT:
      paymentChipText = "Cash Payment";
      break;

    default:
      break;
  }

  return (
    <>
      <Box pb={1.2}>
        <Typography
          fontWeight={"bold"}
          fontSize={"1.5rem"}
          sx={{
            color: channelingStatus.color,
          }}
        >
          {channelingStatus.status}
        </Typography>
      </Box>

      <Grid container columnSpacing={1.5} rowSpacing={1}>
        <Grid item xs={12} sm={7}>
          <ContentCard
            sx={{
              pl: { sm: 2 },
              pb: 3,
              boxShadow: 0.5,
              border: "1px solid #ececec",
            }}
          >
            <DoctorSummery
              doctorImage={
                doctor.doctorId
                  ? `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}/${doctor.doctorId}.png`
                  : null
              }
              name={appointment ? appointment.DoctorName : ""}
              qualifications={`${doctorSpecialization} REG No :${
                doctor.RegistrationNumber ? doctor.RegistrationNumber : ""
              }`}
            />
            <Grid container mt={3}>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment No"}
                  content={appointment ? appointment.AppointmentNumber : ""}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment Date"}
                  content={
                    appointment
                      ? new Date(
                          appointment.SessionStartTime
                        ).toLocaleDateString()
                      : ""
                  }
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment Time"}
                  content={
                    appointment
                      ? new Date(
                          appointment.SessionStartTime
                        ).toLocaleTimeString()
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <Grid container mt={4}>
              <Grid item xs>
                <TitleAndContent
                  title={"Patient Name"}
                  content={patient.name ? patient.name : "-"}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Mobile no"}
                  content={patient.phoneNo ? patient.phoneNo : "-"}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Email"}
                  content={patient.email ? patient.email : "-"}
                />
              </Grid>
            </Grid>
          </ContentCard>
        </Grid>

        <Grid item xs>
          <ContentCard sx={{ boxShadow: 0.5, border: "1px solid #ececec" }}>
            <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
              Payment Details
            </Typography>
            <Box display={"flex"} flexDirection="column" gap={1} mt={2}>
              <TitleAndContent
                title={"Channeling fee"}
                content="Rs. 500.00"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              />
              <TitleAndContent
                title={"Service Charges"}
                content="Rs. 250.00"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              />

              <Divider orientation="horizontal" />
              <FlexBox sx={{ justifyContent: "end", gap: 2 }}>
                <Typography fontWeight={"bold"} sx={{ color: "#787A91" }}>
                  Total :
                </Typography>
                <Typography fontWeight={"bold"}>Rs. 750.00</Typography>
              </FlexBox>
            </Box>
          </ContentCard>

          <ContentCard
            sx={{
              mt: 2,
              boxShadow: 0.5,
              border: "1px solid #ececec",
              display: "flex",
              flexDirection: "column",
              gap: 0.6,
              alignItems: "start",
            }}
          >
            <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
              Payment Method
            </Typography>
            <Chip label={paymentChipText} color="primary" size="small" />
            {paymentMethod === PAYMENT_METHODS.CASH_PAYMENT && (
              <Typography>*Please make the payment to reception</Typography>
            )}
          </ContentCard>
        </Grid>
      </Grid>
    </>
  );
};

export default StepOne;
